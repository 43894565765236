#renovationTable > div{
    box-shadow: none;
    border-radius: 1rem;
    margin-bottom: -1rem;
}
.css-rorn0c-MuiTableContainer-root{
    border-radius: 1rem;

}
#renovationTable{
    border-radius: 1rem;
    border: 2px solid #266ED4;
}
#MuiTableCell-body{/* 
    border-bottom: 2px solid #266ED4 !important; */

}
/* class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft MuiTableCell-sizeSmall css-dsuxgy-MuiTableCell-root" */