#MainContainerApp {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	font-family: "Poppins", sans-serif;
}

.cabecera {
	width: 100%;
	left: 0;
	right: 0;
	vertical-align: top;
	margin: 0 auto;
	padding: 0 auto;

	background-color: #0e2130;
	display: flex;
	align-items: center;
}

.logo {
	width: 100%;
	height: 100px;
	background: #0e2130;
}

.logodominicana {
	margin: 0 auto;
	padding: 0 auto;
	position: relative;
}

footer {
	width: 100%;
	text-align: center;
	background: #0e2130;
	color: white;
}

.routesContainer {
	display: flex;
	flex-direction: column;
	margin: auto;
	align-items: center;
	width: 50vw;
}

@media screen and (max-width: 1366px) {
	.routesContainer {
		width: 90vw;
	}
}
