#SearchBar {
	display: flex;
	align-items: center;
}

.SearchIcon {
	color: white;
	margin-left: -2.48rem;
	margin-top: 4px;
	margin-right: 1.5rem;
	font-size: 2rem;
	cursor: pointer;
	background-color: #0d3c9d;
	border-radius: 4rem;
	padding: 0.2rem;
	z-index: 1000;
}

#BalanceChecker {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5rem;
	border: 2px solid #266ed4;
	padding: 0 2rem 0 0.5rem;
	margin-left: 1rem;
}
#BalanceChecker > p {
	margin: 0.55rem;
	width: max-content;
}

#insurancesContainer {
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
	background-color: transparent !important;
	width: -webkit-fill-available;
	justify-content: space-between;
}

.MuiTableRow-head > th {
	background-color: #c9d4da;
	color: #1b3e90 !important;
}
.MuiTableRow-head > th > span {
	width: max-content;
}
.roundedBorder .MuiOutlinedInput-notchedOutline {
	border: 2px solid #266ed4 !important;
	border-radius: 5rem !important;
}
.css-1xqa8dt-MuiTableContainer-root {
	margin: 0 !important;
	border-radius: 1rem;
}
#navBar {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: inherit;
}

#lookForIntermdediary {
	display: flex;
	align-items: center;
	height: -moz-fit-content;
	height: fit-content;
	width: fit-content;
	background: #0e3e9b;
	margin-top: 4rem;
	padding: 0 1.5rem;
	border-radius: 2rem;
	color: white;
	margin-right: auto;
}

#lookForIntermdediary > p {
	width: max-content;
	margin-right: 0.7rem;
}

#lookForIntermdediaryIcon {
	font-size: 2rem;
}

#genLinkButton {
	display: flex;
	align-items: center;
	height: -moz-fit-content;
	height: fit-content;
	width: 11rem;
	background: #0e3e9b;
	margin-top: 4rem;
	padding: 0 1.5rem;
	border-radius: 2rem;
	color: white;
}

#genLinkButton > p {
	width: max-content;
	margin-right: 0.7rem;
}

#genLinkButtonIcon {
	font-size: 2rem;
}
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background: #3c7dd7;
	border: 0px none #ffffff;
	border-radius: 100px;
}
::-webkit-scrollbar-thumb:active {
	background: #283c9f;
}
::-webkit-scrollbar-track {
	background-color: transparent;
	border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-track:active {
	background-color: transparent;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

#redDot {
	background-color: red;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.7rem;
	margin-left: -0.9rem;
}
